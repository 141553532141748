import React, {Suspense, lazy, useEffect, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonRouterOutlet, IonPage} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';

import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/Main.scss';
import {
    removeLocalOrSessionStateItem,
    useLocalOrSessionState,
    useLocalState
} from "./services/LocalState";
import API from "./api/Calls";
import {
    ActionPerformed,
    PushNotifications,
    PushNotificationSchema, Token,
} from "@capacitor/push-notifications";
import {App as PluginApp, AppLaunchUrl} from "@capacitor/app";
import {Device} from "@capacitor/device";
import AlertBox from "./components/AlertBox";
import Tabs from "./components/Tabs";

import {Badge} from '@awesome-cordova-plugins/badge';
import useShop, {ShopInit, ShopInterface} from "./services/Shop";
import useForum from "./services/Forum";
import useCustomer, {CustomerInit, CustomerInterface} from "./services/Customer";
import {TabRoutes} from "./classes/TabRoutes";

const ChooseCommunity = lazy(() => import("./pages/customer/ChooseCommunity"));
const FavoriteCommunities = lazy(() => import("./pages/customer/FavoriteCommunities"));
const Login = lazy(() => import("./pages/customer/Login"));
const Registration = lazy(() => import('./pages/customer/Registration'));
const RegisterCommunity = lazy(() => import('./pages/customer/RegisterCommunity'));
const SendPasswordResetLink = lazy(() => import('./pages/customer/SendPasswordResetLink'));
const ResetPassword = lazy(() => import('./pages/customer/ResetPassword'));
const GoToCharityByShop = lazy(() => import('./pages/GoToCharityByShop'));
const ProcessLrpUuid = lazy(() => import('./pages/ProcessLrpUuid'));
const Error = lazy(() => import('./pages/Error'));
const ChangeWeakPassword = lazy(() => import('./pages/customer/ChangeWeakPassword'));
const PaymentStatus = lazy(() => import('./pages/PaymentStatus'));
const FeedbackForm = lazy(() => import('./pages/church/more/feedback/FeedbackForm'));

interface GlobalStateInterface {
    forum: any,
    shop: ShopInterface,
    customer: CustomerInterface
    // customer: any
}

const globalStateInit = {
    forum: {},
    shop: ShopInit,
    customer: CustomerInit
    // customer: {}
}

const GlobalContext = React.createContext<GlobalStateInterface>(globalStateInit);

export const useGlobalState = () => {
    return React.useContext(GlobalContext);
}

const App: React.FC = (props) => {

    const shop = useShop();
    const customer = useCustomer(shop);
    const forum = useForum(shop);

    let pathArray = window.location.pathname.split('/');
    const currentShopId = (/^\d+$/.test(pathArray[1]) ? parseInt(pathArray[1]) : null);

    useEffect(() => {
        if (currentShopId !== null) {
            console.log('Set shopId from route: ' + currentShopId);
            shop.setShopId(currentShopId);
        }
    }, [currentShopId]);

    const [deviceToken, setDeviceToken] = useLocalOrSessionState('deviceToken');
    const [updateRequired, setUpdateRequired] = useLocalState('updateRequired');
    const [recentVersion, setRecentVersion] = useLocalState('recentVersion');
    const [appUrl, setAppUrl] = useLocalState('appUrl');
    const [apiSetting, setApiSetting] = useLocalState('apiSetting');
    const [apiSettingChecked, setApiSettingChecked] = useState<boolean>(false);
    const [pushNotification, setPushNotification] = useState<any>({
        title: "init title",
        body: "init body",
        attached: {}
    });
    const [showNotification, setShowNotification] = useState(false);
    const [platform, setPlatform] = useLocalState('platform');
    const [listenersSet, setListenersSet] = useState(false);
    const [showLoginQRScannedNotShop, setShowLoginQRScannedNotShop] = useState(false);
    const [showLoginQRScannedShop, setShowLoginQRScannedShop] = useState(false);
    const [newShopId, setNewShopId] = useState("");

    const [favoriteShops, setFavoriteShops] = useLocalState('favoriteShops');
    const [currentLoggedInShop, setCurrentLoggedInShop] = useLocalOrSessionState('currentLoggedInShop');

    useEffect(() => {
        if (!apiSettingChecked) {
            Device.getInfo().then((response) => {
                if (response['operatingSystem'] === 'ios') {
                    API.appVersion.getIos().then((response) => {
                        if (response.data.updateRequired) {
                            setUpdateRequired(true);
                            setRecentVersion(response.data.version);
                            setAppUrl(response.data.appUrl);
                        }
                        setApiSetting(response.data.apiSetting);
                        setApiSettingChecked(true)
                    }, (err) => {
                        console.log(err.response);
                    })
                } else {
                    API.appVersion.getAndroid().then((response) => {
                        if (response.data.updateRequired) {
                            setUpdateRequired(true);
                            setRecentVersion(response.data.version);
                            setAppUrl(response.data.appUrl);
                        }
                        setApiSetting(response.data.apiSetting);
                        setApiSettingChecked(true)
                    }, (err) => {
                        console.log(err.response);
                    })
                }
            }, (err) => {
                console.log(err.response.data.msg);
            });
        }
    }, [apiSettingChecked]);

    useEffect(() => {
        if (!listenersSet && apiSettingChecked) {
            Device.getInfo().then((info) => {
                let devicePlatform = info.platform.toString();

                if (devicePlatform === "web") {
                    devicePlatform = 'Browser';
                } else if (devicePlatform === "android") {
                    devicePlatform = 'Android';
                } else if (devicePlatform === "ios") {
                    devicePlatform = 'iOS';
                }
                if (devicePlatform !== "Browser") {
                    console.log("PushNotifications listeners are being set");
                    PushNotifications.addListener('registration',
                        (token: Token) => {
                            console.log('Push notifications registration called with token: ' + token.value);
                            console.log('old token: ' + deviceToken);
                            let oldToken = deviceToken;
                            setDeviceToken(token.value);


                            setPlatform(devicePlatform);
                            if (oldToken !== null && oldToken !== token.value) {
                                API.appClient.changeDeviceTokenAndUpdate(oldToken, token.value, devicePlatform).then(function (data) {
                                    console.log("deviceToken successfully sent to API for update and stored in device service");
                                }, function (err) {
                                    alert(typeof err.data === 'object' && err.data !== null && 'msg' in err.data ? err.data.msg : 'Fout bij het versturen van de bijgewerkte device token (E1)');
                                });
                            } else {
                                API.appClient.createOrUpdate(token.value, devicePlatform).then(function (data) {
                                    console.log("deviceToken successfully sent to API and stored in device service");
                                }, function (err) {
                                    alert(typeof err.data === 'object' && err.data !== null && 'msg' in err.data ? err.data.msg : 'Fout bij het versturen van de device token (E1)');
                                });
                            }
                        });


                    PushNotifications.addListener('registrationError',
                        (error: any) => {
                            // alert('Error on registration: ' + JSON.stringify(error));
                        }
                    );

                    PushNotifications.addListener('pushNotificationReceived',
                        (notification: PushNotificationSchema) => {
                            PluginApp.getState().then((result) => {
                                if (result.isActive) {
                                    let buttons: any = ['OK'];
                                    let hidden = false;
                                    let attachedData: any = undefined;

                                    if (notification.data.attached !== undefined) {
                                        attachedData = typeof notification.data.attached === "string" ? JSON.parse(notification.data.attached) : notification.data.attached;

                                        if (attachedData.type !== undefined && (attachedData.id !== undefined || attachedData.type === 'forum')) {
                                            let goTo = '/error/attached-type-' + attachedData.type;
                                            if (attachedData.type === 'doel') {
                                                goTo = `/${notification.data.shopId}/financial/charity/${attachedData.id}`;
                                            } else if (attachedData.type === 'news') {
                                                goTo = `/${notification.data.shopId}/actual/news/${attachedData.id}`;
                                            } else if (attachedData.type === 'blog') {
                                                //ToDo: wanneer blogs geimplementeerd zijn
                                            } else if (attachedData.type === 'forum') {
                                                goTo = `/${notification.data.shopId}/community/tab?startSegment=forum`;
                                            }
                                            buttons = [{
                                                text: 'Bekijk', handler: () => {
                                                    window.location.href = goTo
                                                }
                                            }, {text: 'Annuleer', role: 'cancel'}];
                                        }
                                    }

                                    setPushNotification({
                                        title: notification.data.title,
                                        body: notification.data.body,
                                        attached: notification.data.attached,
                                        buttons: buttons
                                    });

                                    if (attachedData !== undefined && attachedData.newMessageCountForUser !== undefined) {
                                        hidden = true;
                                        Badge.isSupported().then(
                                            (status) => {
                                                if (status === false) {
                                                    console.log('Badges not supported, set pop-up push push notification for count: ' + attachedData.newMessageCountForUser);
                                                    if (attachedData.newMessageCountForUser > 0) {
                                                        setShowNotification(true);
                                                    }
                                                } else {
                                                    console.log('Setting badge to ' + attachedData.newMessageCountForUser);
                                                    Badge.set(attachedData.newMessageCountForUser);
                                                }
                                            },
                                            (error) => {
                                                console.log('Badges support error, set pop-up push push notification for count: ' + attachedData.newMessageCountForUser);
                                                if (attachedData.newMessageCountForUser > 0) {
                                                    setShowNotification(true);
                                                }
                                            }
                                        );
                                    }

                                    if (hidden === false) {
                                        setShowNotification(true);
                                    }
                                }
                            })
                        }
                    );

                    PushNotifications.addListener('pushNotificationActionPerformed',
                        (notification: ActionPerformed) => {
                            let buttons: any = ['OK'];
                            let hidden = false;
                            let attachedData: any = undefined;

                            if (notification.notification.data.attached !== undefined) {
                                attachedData = typeof notification.notification.data.attached === "string" ? JSON.parse(notification.notification.data.attached) : notification.notification.data.attached;

                                if (attachedData.type !== undefined && (attachedData.id !== undefined || attachedData.type === 'forum')) {
                                    let goTo = '/error/attached-type-' + attachedData.type;
                                    if (attachedData.type === 'doel') {
                                        goTo = `/${notification.notification.data.shopId}/financial/charity/${attachedData.id}`;
                                    } else if (attachedData.type === 'news') {
                                        goTo = `/${notification.notification.data.shopId}/actual/news/${attachedData.id}`;
                                    } else if (attachedData.type === 'blog') {
                                        //ToDo: wanneer blogs geimplementeerd zijn
                                    } else if (attachedData.type === 'forum') {
                                        goTo = `/${notification.notification.data.shopId}/community/tab?startSegment=forum`;
                                    }
                                    buttons = [{
                                        text: 'Bekijk', handler: () => {
                                            window.location.href = goTo
                                        }
                                    }, {text: 'Annuleer', role: 'cancel'}];
                                }
                            }

                            setPushNotification({
                                title: notification.notification.data.title,
                                body: notification.notification.data.body,
                                attached: notification.notification.data.attached,
                                buttons: buttons
                            });

                            if (attachedData !== undefined && attachedData.newMessageCountForUser !== undefined) {
                                hidden = true;
                                Badge.isSupported().then(
                                    (status) => {
                                        if (status === false) {
                                            console.log('Badges not supported, set pop-up push push notification for count: ' + attachedData.newMessageCountForUser);
                                            if (attachedData.newMessageCountForUser > 0) {
                                                setShowNotification(true);
                                            }
                                        } else {
                                            console.log('Setting badge to ' + attachedData.newMessageCountForUser);
                                            Badge.set(attachedData.newMessageCountForUser);
                                        }
                                    },
                                    (error) => {
                                        console.log('Badges support error, set pop-up push push notification for count: ' + attachedData.newMessageCountForUser);
                                        if (attachedData.newMessageCountForUser > 0) {
                                            setShowNotification(true);
                                        }
                                    }
                                );
                            }

                            if (hidden === false) {
                                setShowNotification(true);
                            }
                        }
                    );
                } else {
                    setPlatform(devicePlatform);
                }
            });

            PluginApp.addListener('appUrlOpen', (data: AppLaunchUrl) => {
                let urlParts = data.url.split('://');

                if (urlParts[0] === 'app.skgcollect.appostel') {
                    let paramparts = urlParts[1].split('/');
                    if (paramparts[0] === 'shop' || paramparts[0] === 'church' || /^\d+$/.test(paramparts[0])) {
                        let shopId = /^\d+$/.test(paramparts[0]) ? paramparts[0] : paramparts[1];

                        if (customer.customerId === null) {
                            window.location.href = `/${shopId}/login`
                        } else {
                            if (shop.shopId == null || shopId !== shop.shopId.toString()) {
                                setNewShopId(shopId);
                                setShowLoginQRScannedNotShop(true)
                            } else {
                                setShowLoginQRScannedShop(true);
                            }
                        }
                    } else {
                        window.location.href = '/' + (urlParts[1]).split('?')[0];
                    }
                } else if (data.url.startsWith('https://app.appostel.nl/')) {
                    let urlObject = new URL(data.url);
                    console.log('Go to: ' + urlObject.pathname + urlObject.search);
                    window.location.href = urlObject.pathname + urlObject.search;
                }
            })
            setListenersSet(true);
        }
    }, [apiSettingChecked]);

    const logOut = (newShopId: string) => {
        if (customer.customerId !== null && customer.customerSessionToken !== null) {
            API.customer.logout(customer.customerSessionToken, customer.customerId).then(() => {
                customer.logoutAndClearCustomer();
                removeLocalOrSessionStateItem('currentLoggedInShop');
                window.location.href = `/${newShopId}/login`;
            }, (err) => {
                console.log('api::customer::logout failed');
                if (err.response !== undefined) {
                    console.log(err.response.data);
                }
            });
        } else {
            window.location.href = `/${newShopId}/login`;
        }
    }

    const goToStartPage = () => {
        let favs = JSON.parse(favoriteShops);

        if (favs !== null && favs.length == 1) {
            window.location.href = '/' + favs[0] + '/login';
        } else if (favs !== null && favs.length > 1) {
            if (currentLoggedInShop !== null) {
                window.location.href = `/${currentLoggedInShop}/welcome`
            } else {
                window.location.href = '/favourites';
            }
        } else {
            window.location.href = '/chooseCommunity';
        }
    }

    if (apiSettingChecked) {
        return (
            <IonApp>
                <GlobalContext.Provider value={{shop, customer, forum}}>
                    {showNotification &&
                        <IonPage>
                            <AlertBox showBox={true} header={pushNotification.title} message={pushNotification.body}
                                      buttons={pushNotification.buttons}
                                      onDidDismiss={() => setShowNotification(false)}/>
                        </IonPage>
                    }
                    {showLoginQRScannedNotShop &&
                        <IonPage>
                            <AlertBox showBox={true} header={"Fout"}
                                      message={'U bent niet ingelogd bij deze gemeente. Wilt u hier uitloggen en inloggen op deze gemeente?'}
                                      buttons={[
                                          {
                                              text: "Uitloggen",
                                              handler: () => logOut(newShopId)
                                          },
                                          {
                                              text: "Ingelogd blijven"
                                          }
                                      ]}/>
                        </IonPage>
                    }
                    {showLoginQRScannedShop &&
                        <IonPage>
                            <AlertBox showBox={true} header={"Fout"} message={"U bent al ingelogd bij deze gemeente."}/>
                        </IonPage>

                    }
                    <Suspense fallback={<IonPage/>}>
                        <IonReactRouter>
                            <IonRouterOutlet>
                                <Route exact path="/" render={() => {
                                    goToStartPage();
                                    return '';
                                }}/>
                                <Route path="/favourites" component={FavoriteCommunities}/>
                                <Route path="/chooseCommunity" component={ChooseCommunity}/>
                                <Route path="/registerCommunity" component={RegisterCommunity}/>
                                <Route path={TabRoutes.BaseRoute} component={Tabs}/>
                                <Route path={`${TabRoutes.BaseRoute}/login`} component={Login}/>
                                <Route path={`${TabRoutes.BaseRoute}/login/:validationToken`} component={Login}/>
                                <Route path={`${TabRoutes.BaseRoute}/registration`} component={Registration}/>
                                <Route path={`${TabRoutes.BaseRoute}/resetpassword`} component={SendPasswordResetLink}/>
                                <Route path={`${TabRoutes.BaseRoute}/resetpassword/:resetToken`}
                                       component={ResetPassword}/>
                                <Route path={`${TabRoutes.BaseRoute}/setpassword/:resetToken`}
                                       component={ResetPassword}/>
                                <Route path={`${TabRoutes.BaseRoute}/changeweakpassword/:resetToken`}
                                       component={ChangeWeakPassword}/>
                                <Route path="/error/:id" component={Error}/>
                                <Route path={`${TabRoutes.BaseRoute}/paymentstatus/:paymentdetails`}
                                       component={PaymentStatus}/>
                                <Route path="/lrp/:type/:uuid" component={ProcessLrpUuid}/>
                                <Route path="/shop/:shopId(\\d+)/charity/:charityId(\\d+)"
                                       component={GoToCharityByShop}/>
                                <Route path="/customer/feedback" component={FeedbackForm}/>
                                <Route render={(props) => {
                                    return <Error match={props.match} history={props.history}
                                                  location={props.location}/>
                                }}/>
                            </IonRouterOutlet>
                        </IonReactRouter>
                    </Suspense>
                </GlobalContext.Provider>
            </IonApp>
        );
    } else {
        return null;
    }
};

export default App;
